import { Memory } from "./game";
import { setEvent } from "./Platform";

export class Tile {
	public title: string;
	private flipped: boolean;
	private el: HTMLTableDataCellElement;
	private game: Memory;
	protected version: number;
	private matched: boolean;
	constructor(game, title, version) {
		this.title = title;
		this.flipped = false;
		this.game = game;
		this.version = version;
		this.matched = false;
	}

	public setElement(td: HTMLTableDataCellElement) {
		this.el = td;
		// register click or touch events
		setEvent(this.el, this.cardClicked.bind(this));
		// (this.el.querySelector('.back') as HTMLImageElement).style.visibility = 'hidden';
		// (this.el.querySelector('.front') as HTMLImageElement).style.visibility = 'visible';
	}

	public isSame(tile: Tile): boolean {
		if (tile.title === this.title && tile.version === this.version) {
			return true;
		}
		return false;
	}

	public isMatch(tile: Tile): boolean {
		if (tile.title === this.title && tile.version !== this.version) {
			return true;
		}
		return false;
	}

	flipping: any;

	public flip(): void {
		this.flipped = true;
		this.update();
	}

	public unflip(): void {
		this.flipped = false;
		this.update();
	}

	public match(): void {
		this.matched = true;
		this.el.querySelector(".card").classList.add("match");
	}

	private update(): void {
		if (!this.flipped) {
			this.el.querySelector(".card").classList.remove("flipped");
		} else {
			this.el.querySelector(".card").classList.add("flipped");
		}
	}

	private cardClicked(e) {
		if (this.matched) {
			return;
		}
		this.game.handleTileClick(this);
	}
}
