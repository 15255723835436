import { Memory } from "./game";

export function debugLog(...message: any) {
	// console.log(...message);
}

document.addEventListener("DOMContentLoaded", (e) => {
	// eslint-disable-next-line no-new
	new Memory();
});
