export class ASTemplate {
    private id: string;
    private el: HTMLTemplateElement;
    private content: HTMLDivElement;
    
    constructor(id: string) {
      this.id = id;
      this.el = document.getElementById(id) as HTMLTemplateElement;
      document.body.removeChild(this.el);
      if (!this.el) {
          throw Error('Template ' + id + ' not found')
      }
    }

    public setVar(varname, value) {
        this.content.querySelector("span[content='"+ varname +"']").innerHTML = value;
    }

    public addTo(el: Element) {
        this.el.content.childNodes.forEach(cn => {
            el.appendChild(cn.cloneNode(true));
        })
        return el;
    }
}