// var levelConfig = levelConfig || {};
export interface Level {
    level: number;
    tiles: Array<string>;
    time: number;
}
export const LevelConfig =
[
    {
         level: 1,
         tiles: ['cactus', 'fish', 'helicopter'],
         time: 60
    },
    {
        level: 2,
        tiles: ['cactus', 'fish', 'helicopter', 'ice'],
        time: 60
    },
    {
        level: 3,
        tiles: ['cactus', 'fish', 'helicopter', 'ice', 'megaphone'],
        time: 60
    },
    {
        level: 4,
        tiles: ['cactus', 'fish', 'helicopter', 'ice', 'megaphone', 'rocket'],
        time: 60
    },
    {
        level: 5,
        tiles: ['cactus', 'fish', 'helicopter', 'ice', 'megaphone', 'rocket', 'pencil'],
        time: 60
    },
    {
        level: 6,
        tiles: ['cactus', 'fish', 'helicopter', 'ice', 'megaphone', 'rocket', 'pencil',
        'smile'],
        time: 60
    },
    {
        level: 7,
        tiles: ['cactus', 'fish', 'helicopter', 'ice', 'megaphone', 'rocket', 'pencil',
        'smile', 'tree'],
        time: 60
    },
    {
        level: 8,
        tiles: ['cactus', 'fish', 'helicopter', 'ice', 'megaphone', 'rocket', 'pencil',
        'smile', 'tree', 'trumpet'],
        time: 60
    }
] as Array<Level>;


